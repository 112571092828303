<template>
  <div class="icon">
    <img :class="{'clickable': onClick}" @click="onClick" :src="icon" alt="icon" :height="size" :width="size">
  </div>
</template>

<script>
export default {
  name: "IconUI",
  props: {
    icon: {},
    size: {},
    href: {},
    onClick: {}
  }
}
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
  filter: contrast(130%);
}

.icon {
  margin: 10px;
}
</style>
