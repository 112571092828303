<template>
  <div style="max-width: 980px; margin: 0 auto">
    <v-form @submit.prevent.stop="singIn">
      <v-card-text>
        <HeaderDiv>
          <template v-slot:title>
            <h2 class="py-4">Вход в АИС</h2>
          </template>
          <template v-slot:content>
            <div class="d-flex" v-if="$store.state.university.university.name === 'Сургутский государственный университет'">
              <IconUI :on-click="signInViaVK" icon="/icons/icon_vk.svg" size="32"></IconUI>
              <IconUI :on-click="signInViaGoogle" icon="/icons/icon_google.svg" size="32"></IconUI>
            </div>

          </template>

        </HeaderDiv>

        <InputUI
            v-model="credentials.username"
            :disabled="loading"
            placeholder="alice@university.info"
            label="Email"
            name="email"
            inputmode="email"
            type="email"
        />

        <InputUI id="password"
                 v-model="credentials.password"
                 :errors="this.errors.login"
                 :disabled="loading"
                 label="Пароль"
                 placeholder="Пароль"
                 name="password"

                 type="password"
        />


        <v-row align="center">
          <v-col class="pb-0" cols="12" lg="6">
            <ButtonUI @click="singIn" :loading="loading" :disabled="!canSubmit">
              Войти
            </ButtonUI>
          </v-col>
          <v-col class="pb-0" cols="12" lg="6">

            <v-dialog
                v-model="forgot_dialog"
                hide-overlay
                max-width="400"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <ButtonUI @click.prevent.native="on.click" type="secondary">
                  Не помню пароль
                </ButtonUI>
              </template>
              <v-card elevation="0" rounded>
                <v-card-title>Восстановление пароля</v-card-title>
                <v-card-text>
                  <InputUI
                      autofocus
                      v-model="credentials.username"
                      :loading="loading"
                      placeholder="Email"
                      name="email"
                      type="text"
                  />
                  <ButtonUI :loading="loading" color="primary" dark @click.native="forgot">
                    Восстановить пароль
                  </ButtonUI>
                </v-card-text>

              </v-card>
            </v-dialog>

          </v-col>
          <v-col lg="12" class="text-center">
            <v-btn @click.native.prevent="() => $router.push('/auth/signup')" class="text-none" link text href="">Нет аккаунта? Зарегистрируйтесь</v-btn>
          </v-col>


        </v-row>

      </v-card-text>

    </v-form>
    <v-snackbar v-model="snackbar"
                color="success"
    >
      Успешно! Входим в систему ...
      <v-btn
          text
          @click="snackbar= false"
      >
        Закрыть
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar_forgot"
                color="success"
    >
      Если вы были зарегистрированы с этим Email, то пароль был успешно отправлен.
      <v-btn
          text
          @click="snackbar_forgot= false"
      >
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import user from "@/api/user";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import IconUI from "@/components/UI/IconUI.vue";
import HeaderDiv from "@/components/UI/HeaderDiv.vue";
import analytics from "@/api/analytics.js";

export default {
  name: "LoginComponent",
  components: {InputUI, ButtonUI, IconUI, HeaderDiv},
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      loading: false,
      snackbar: false, snackbar_forgot: false,
      forgot_dialog: false,
      errors: {
        login: []
      }
    }
  },
  computed: {
    canSubmit() {
      return this.credentials.username.length > 0 && this.credentials.password.length > 5;
    },
    university() {
      return this.$store.state.university.university;
    }
  },
  watch: {
    "credentials.password": function () {
      this.errors.login = []
    }
  },
  methods: {
    forgot() {
      this.loading = true;
      user.forgot_password({email: this.credentials.username}).then(() => {
        this.forgot_dialog = false;
        this.snackbar_forgot = true;
        this.loading = false;
      });
    },
    signInViaVK() {
      window.location.href = 'https://api.student.surgu.ru/login/vkontakte';
    },
    signInViaGoogle() {
      window.location.href = 'https://api.student.surgu.ru/login/google';
    },
    singIn() {
      this.loading = true
      this.$store.dispatch('authUser', this.credentials).then(() => {
        this.loading = false
        this.snackbar = true
        if(this.$store.state.user.currentUser.uni_id !== this.university.id){
          localStorage.removeItem('access_token');
          window.location = 'https://'+this.$store.state.user.currentUser.university.hostname+'/#/authByToken/'+this.$store.state.user.token;
        }
        if (localStorage.getItem('return_path')) {
          this.$router.push(localStorage.getItem('return_path'))
          localStorage.removeItem('return_path')
        } else this.$router.push('/');

      }).catch(() => {
        this.errors.login = ['Неверный логин или пароль'];
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$store.state.user.currentUser.id > 0) this.$router.push('/');
    analytics.openLoginPage(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)
  }
}
</script>

<style scoped>
</style>
