import { render, staticRenderFns } from "./IconUI.vue?vue&type=template&id=354c1446&scoped=true&"
import script from "./IconUI.vue?vue&type=script&lang=js&"
export * from "./IconUI.vue?vue&type=script&lang=js&"
import style0 from "./IconUI.vue?vue&type=style&index=0&id=354c1446&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354c1446",
  null
  
)

export default component.exports